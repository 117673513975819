<template>
  <div class="container-fluid row">
    <div class="col-md-9" v-if="!data">
      <div class="spinner-border text-center" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div class="col-md-9" v-else>
      <h2 class="title">{{ data[0].title }}</h2>
      <div class="row">
        <div class="col-1">
          <img class="authorPhoto" :src="data[0].authorImgPath" alt="" />
        </div>
        <div class="col-11 date">
          <h6>{{ data[0].date }}</h6>
        </div>
      </div>
      <!-- <img class="mainPhoto" :src="data[0].imgPath" alt="" /> -->

      <p class="desc" v-html="data[0].longDesc"></p>

      <hr />
      <h1 class="title">Zobacz pozostałe wpisy</h1>
      <div class=" row blog-post-container">
        <div class="col-sm-6 blogPosts" v-for="item in randData" :key="item.id">
          <h3 class="title">{{ item.title }}</h3>
          <div class="row">
            <div class="col-2">
              <img class="authorPhoto" :src="item.authorImgPath" alt="" />
            </div>
            <div class="col-10">
              <p class="fullName">{{ item.author }}</p>
              <h6>{{ item.date }}</h6>
            </div>
          </div>
          <!-- <img class="mainPhoto" :src="item.imgPath" alt="" /> -->
          <p v-html="item.shortDesc"></p>

          <router-link :to="'/blog/' + item.id" class="text-uppercase read-more"
            ><h5>read more</h5></router-link
          >
        </div>
      </div>
    </div>
    <div class="col-md-3 ">
      <blog-router></blog-router>
    </div>
  </div>
</template>

<script>
import Data from "../data/blog.data";
import BlogRouter from "../components/BlogRouter";
export default {
  components: {
    BlogRouter
  },
  data: function() {
    return {
      id: "",
      data: null,
      randData: []
    };
  },
  created() {
    this.reload();
    this.randData = this.shuffle(Data).slice(0, 2);
  },
  watch: {
    $route() {
      this.reload();
    }
  },
  methods: {
    reload() {
      this.id = this.$route.params.id;
      this.data = Data.filter(item => {
        return item.id === this.id;
      });
    },
    shuffle(array) {
      let currentIndex = array.length,
        temporaryValue,
        randomIndex;
      while (0 !== currentIndex) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
      }

      return array;
    }
  }
};
</script>

<style scoped>
.container-fluid {
  max-width: 1450px;
  margin: 70px auto auto auto;
}
.title {
  margin: 30px 0;
}
li{
  margin-left: 100px;
}
hr {
  margin-top: 30px;
}
.date p {
  margin: 0;
}
.mainPhoto {
  margin: 10px 0;
  width: 100%;
  max-height: 400px;
  object-fit: cover;
}
.desc {
  text-align: justify;
  margin: 10px 0;
}

.fullName {
  font-size: 1rem;
  margin: 0;
}

.authorPhoto {
  width: 40px;
  border-radius: 100px;
}
a {
  color: black;
}
a:hover {
  text-decoration: none;
}
.mainPhoto {
  margin: 6px 0;
  width: 100%;
  object-fit: cover;
  height: 220px;
}
.pagination {
  margin: 0 auto;
}
.pagination .next,
.pagination .prev {
  background-color: transparent;
  border: 2px solid black;
  border-radius: 50px;
  height: 40px;
  width: 40px;
  font-size: 20px;
  padding: 2px;
  margin: 32px 3px;
}

.read-more h5 {
  text-align: right;
  font-size: 16px;
  text-decoration: underline;
}
</style>
